import { StorageKey } from "core/appSettings";
import { lazy, ReactElement, Suspense } from "react";

interface FooterProps {
  /** Name of the FRONTEND component that should be rendered  */
  uiComponent?: string;
  config?: any;
}

export function FooterLoader({
  uiComponent,
  config,
}: FooterProps): ReactElement {
  const environmentLanguage = process.env.REACT_APP_SELFCARE_LANGUAGE;
  const localStorageLanguage = localStorage.getItem(
    StorageKey.SELFCARE_LANGUAGE
  );
  const defaultLanguage = localStorageLanguage || environmentLanguage || "en";

  /**
   * Below line takes the uiComponent string specified in the app config and
   * And lazy loads the correct footer depending on value. When adding new
   * footers they need to be the default export of the file since import()
   * syntax requires it and cant handle named exports (See XploraFooter).
   */
  const CompanyFooter = lazy(() => import(`./footers/${uiComponent}`));

  return (
    <Suspense>
      <CompanyFooter language={defaultLanguage} config={config} />
    </Suspense>
  );
}

import { Route, Routes } from "common/routing";
import { useFetchSelfCareAppFooter } from "core/api/hooks/screens/useFetchSelfcareAppFooter";
import { StorageKey } from "core/appSettings";
import { useTranslation } from "i18n";
import { Logo } from "layout/SelfcareAppLayout/Logo";
import { ScreenContentWrapper } from "layout/SelfcareAppLayout/SelfCareAppLayout.styles";
import {
  LoginInfoBlock,
  OneTimePasswordForm,
  SelfcareLoginLayout,
} from "layout/SelfcareLoginLayout";
import { ReactElement } from "react";
import { FooterLoader } from "screens/views/FooterView/FooterLoader";
import banner from "../../../assets/login-banner.xplora.jpg";
import xploraLogo from "../../../assets/logo.xplora.png";

/**
 * A component for providing the login route with a login form
 * @returns A react jsx element
 */
export function LoginScreen(): ReactElement {
  const { t } = useTranslation("login");
  const { data } = useFetchSelfCareAppFooter(
    process.env.REACT_APP_APP_CONFIG_ID
  );

  const environmentLanguage = process.env.REACT_APP_SELFCARE_LANGUAGE;
  const localStorageLanguage = localStorage.getItem(
    StorageKey.SELFCARE_LANGUAGE
  );
  const defaultLanguage = localStorageLanguage || environmentLanguage || "en";

  return (
    <ScreenContentWrapper
      sx={{
        height: "100vh",
      }}
    >
      <Routes>
        <Route
          index
          element={
            <SelfcareLoginLayout
              top={
                <Logo
                  width="90px"
                  height="90px"
                  sxOverride={{ margin: 0 }}
                  src={xploraLogo}
                />
              }
              left={
                <OneTimePasswordForm
                  cardMediaSrc={banner}
                  defaultLanguage={defaultLanguage}
                />
              }
              right={
                <LoginInfoBlock
                  title={t("loginInfo.title", { lng: defaultLanguage })}
                  content={t("loginInfo.content", { lng: defaultLanguage })}
                />
              }
            />
          }
        />
      </Routes>
      {data && data.uiComponent && (
        <FooterLoader
          uiComponent={data.uiComponent}
          config={data.footerConfig}
        />
      )}
    </ScreenContentWrapper>
  );
}

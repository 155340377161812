import action from "i18n/lang/gb/action";
import common from "i18n/lang/gb/common";
import error from "i18n/lang/gb/error";
import invoice from "i18n/lang/gb/invoice";
import languages from "i18n/lang/gb/languages";
import login from "./login";
import selfcare from "./selfcare";

export const gb = {
  action,
  common,
  invoice,
  languages,
  error,
  login,
  selfcare,
} as const;

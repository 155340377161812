import { FormCheckBox, FormDatePicker, Stack } from "common/components";
import { addFlowStepPrefix } from "common/utils";
import { BindsFlowData, BindsFlowStep } from "core/api";
import { useTranslation } from "i18n";
import { useState } from "react";
import { FlowStepComponentProps } from "../models";
import { StyledStepContentWrapper } from "../steps.styles";

export type BindsProps = FlowStepComponentProps<BindsFlowStep, BindsFlowData>;

/**
 * Binds step component to view/edit adjustment date and optional override bind settings.
 */
export function Binds({ control, data, flowStep }: BindsProps) {
  const { t } = useTranslation(["common"]);

  const { adjustmentDate, overrideBinds, supressFees } = data;

  const [enableAdjustmentDate, setAdjustmentDateEnabled] = useState(
    flowStep.config.allowedToChangeAdjustmentDate
  );

  return (
    <StyledStepContentWrapper>
      <FormDatePicker
        control={control}
        label={t("common:adjustmentDate")}
        fieldName={addFlowStepPrefix(flowStep, "adjustmentDate")}
        defaultValue={adjustmentDate}
        required
        disabled={!enableAdjustmentDate}
      />

      <Stack>
        <FormCheckBox
          control={control}
          fieldName={addFlowStepPrefix(flowStep, "overrideBinds")}
          label={t("common:overrideBinds")}
          defaultValue={overrideBinds}
          disabled={!flowStep.config.allowedToOverrideBinds}
          onClick={() =>
            setAdjustmentDateEnabled(
              flowStep.config.allowedToChangeAdjustmentDate ||
                !enableAdjustmentDate
            )
          }
          sx={{ margin: 0 }}
        />

        <FormCheckBox
          size="small"
          control={control}
          fieldName={addFlowStepPrefix(flowStep, "supressFees")}
          label={t("common:supressFees")}
          defaultValue={supressFees}
          disabled={!flowStep.config.allowedToSupressFees}
          dependsOn={{
            key: { key: "overrideBinds", variant: "" as any },
            disabledIf: false,
            resetIfDisabled: true,
          }}
          sx={{ margin: 0, marginLeft: 4 }}
        />
      </Stack>
    </StyledStepContentWrapper>
  );
}

import { PageNotFound } from "404";
import { FontIcon, Logout, Menu } from "common/assets/icons";
import {
  AppBar,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  Theme,
  Toolbar,
  Typography,
  useTheme,
} from "common/components";
import {
  NavLink,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "common/routing";
import { isAppleMobileDevice, isMobileDevice } from "common/utils";
import { ActionDialog } from "core/actions";
import {
  EntityType,
  useFetchCurrentUser,
  useFetchScreen,
  useFetchSelfCareApp,
} from "core/api";
import {
  LoadingScreen,
  Screen,
  ScreenContentComponent,
  ViewsContainer,
  addToScreenEntities,
  buildLoadedEntities,
} from "core/components";
import { useTranslation } from "i18n";
import { importView } from "screens";
import { FooterView } from "screens/views/FooterView";
import { GoBackButton } from "./GoBackButton";
import { HeaderNavLink } from "./HeaderNavLink";
import { Logo } from "./Logo";
import { LogoutButton, useLogoutDialog } from "./LogoutButton";
import { ScreenLevelContext, useFirstScreenLevel } from "./ScreenLevelContext";
import { Layout, ScreenContentWrapper } from "./SelfCareAppLayout.styles";
import { useDrawerState, useResponsiveOverflow } from "./overflowUtils";

interface SelfcareAppLayoutProps {
  logoSrc?: string;
}

export function SelfcareAppLayout({ logoSrc }: SelfcareAppLayoutProps) {
  const { data: app } = useFetchSelfCareApp(
    process.env.REACT_APP_APP_CONFIG_ID
  );
  const { data: screen } = useFetchScreen(app?.screenId);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { data: user } = useFetchCurrentUser();

  if (!screen) {
    return (
      <Box height="100vh">
        <LoadingScreen />
      </Box>
    );
  }

  if (pathname === "/") {
    const redirectPath =
      screen.redirectPath ?? screen.screens[0]?.routeProps.path;
    if (redirectPath) {
      return <Navigate to={redirectPath} />;
    }
  }

  const isMobile = isMobileDevice();
  const iOS = isAppleMobileDevice();

  const ContentComponent: ScreenContentComponent = ({ title }) => {
    const { t } = useTranslation(["common"]);
    const { LogoutDialog, toggleOpen } = useLogoutDialog();
    const theme = useTheme();
    const { firstLevel } = useFirstScreenLevel();
    const { OverflowContainer, isContentOverflow: isSmallScreen } =
      useResponsiveOverflow();
    const { open, toggleDrawer } = useDrawerState(!isSmallScreen);

    return (
      <>
        <AppBar
          position="static"
          color="transparent"
          elevation={isMobile ? 0 : undefined}
          sx={{
            bgcolor: !isMobile ? theme.palette.background.paper : undefined,
          }}
        >
          <ActionDialog />
          {isMobile ? (
            <Toolbar
              sx={{
                display: "grid",
                gridAutoFlow: "column",
                gridTemplateColumns: "40px 1fr 40px",
                gap: 1,
              }}
            >
              {firstLevel ? <LogoutButton /> : <GoBackButton />}
              <Typography variant="h3" component="h1" textAlign="center">
                {title}
              </Typography>
            </Toolbar>
          ) : (
            <Toolbar
              sx={{
                ...viewsContainerSx,
                display: "grid",
                gridTemplateColumns: "min-content auto",
                gap: 1,
                height: "100px",
              }}
            >
              <Logo src={logoSrc} />
              <OverflowContainer sx={{ height: "100%" }}>
                {isSmallScreen ? (
                  <>
                    <IconButton
                      aria-label={t("common:navigation")}
                      onClick={toggleDrawer(true)}
                    >
                      <Menu />
                    </IconButton>
                    <SwipeableDrawer
                      anchor="right"
                      open={open}
                      onClose={toggleDrawer(false)}
                      onOpen={toggleDrawer(true)}
                      disableBackdropTransition={!iOS}
                      disableDiscovery={iOS}
                    >
                      <List sx={{ width: 250, "& > *": { width: "100%" } }}>
                        {screen.screens.map(
                          ({ title, routeProps, icon, screenId }) => (
                            <ListItem key={screenId} disablePadding>
                              <NavLink to={routeProps.path} fullWidth>
                                <ListItemButton>
                                  <ListItemIcon>
                                    {icon && <FontIcon icon={icon} />}
                                  </ListItemIcon>
                                  <ListItemText primary={title} />
                                </ListItemButton>
                              </NavLink>
                            </ListItem>
                          )
                        )}
                      </List>
                      <Divider />
                      <List>
                        <ListItem disablePadding>
                          <ListItemButton onClick={toggleOpen}>
                            <ListItemIcon>
                              <Logout />
                            </ListItemIcon>
                            <ListItemText primary={t("common:logout")} />
                          </ListItemButton>
                        </ListItem>
                      </List>
                    </SwipeableDrawer>
                  </>
                ) : (
                  <>
                    {screen.screens.map(({ title, routeProps, screenId }) => (
                      <HeaderNavLink
                        key={screenId}
                        variant="text"
                        to={routeProps.path}
                        className="appbar__scnavlink--text"
                        data-cy={`selfcare-navlink-${routeProps.path}`}
                      >
                        <p>{title}</p>
                      </HeaderNavLink>
                    ))}
                    <HeaderNavLink
                      variant="text"
                      to="/logout"
                      onClick={(event) => {
                        event.preventDefault();
                        toggleOpen();
                      }}
                      className="appbar__scnavlink--text"
                    >
                      <p>{t("common:logout")}</p>
                    </HeaderNavLink>
                  </>
                )}
              </OverflowContainer>
            </Toolbar>
          )}
          <LogoutDialog />
        </AppBar>
        <ViewsContainer sx={viewsContainerSx}>
          <Box display="grid" gap={2} marginX={2} marginTop={4}>
            <Typography variant="h3" component="h1" color="primary.main">
              {title}
            </Typography>
          </Box>
        </ViewsContainer>
      </>
    );
  };

  const content = (
    <ScreenContentWrapper>
      <Routes>
        {screen.screens.map(({ screenId, routeProps }) => (
          <Route
            key={screenId}
            path={routeProps.path + "/*"}
            element={
              <ScreenLevelContext.Provider
                value={{ firstLevelPath: routeProps.path }}
              >
                <Screen
                  screenId={screenId}
                  contentComponent={ContentComponent}
                  pageNotFoundComponent={PageNotFound}
                  importView={importView}
                  screenEntities={addToScreenEntities(
                    {},
                    screen.entityType ?? EntityType.NONE,
                    user
                  )}
                  loadedEntities={buildLoadedEntities(screen, undefined, user)}
                  viewsContainerSx={viewsContainerSx}
                />
              </ScreenLevelContext.Provider>
            }
          />
        ))}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      {app?.footerViewId && <FooterView viewId={app.footerViewId} />}
    </ScreenContentWrapper>
  );

  if (isMobile) {
    return (
      <Layout>
        {content}
        <BottomNavigation
          showLabels
          value={
            screen.screens.find(({ routeProps }) =>
              pathname.startsWith(routeProps.path)
            )?.routeProps.path
          }
          onChange={(event, newValue) => {
            navigate(newValue);
          }}
          sx={{
            boxShadow: (theme) => `4px 0px 5px ${theme.palette.grey[500]}`,
          }}
        >
          {screen.screens.map(({ screenId, title, icon, routeProps }) => (
            <BottomNavigationAction
              key={screenId}
              label={title}
              value={routeProps.path}
              icon={<FontIcon icon={icon} />}
            />
          ))}
        </BottomNavigation>
      </Layout>
    );
  }

  return <Box height="100vh">{content}</Box>;
}

const viewsContainerSx = {
  width: "100%",
  margin: "0 auto",
  maxWidth: (theme: Theme) => theme.breakpoints.values.lg,
};

import { API, APIQueryResult, useAPIQuery } from "core/api";

interface FooterProps {
  uiComponent?: string;
  /** specific footer config with sensitive information stripped */
  footerConfig?: any;
}

export function useFetchSelfCareAppFooter(
  appConfigId?: string
): APIQueryResult<FooterProps> {
  return useAPIQuery<FooterProps>(
    ["apps/footer"],
    API.config.appFooter,
    {
      pathParams: {
        appConfigId: appConfigId ?? "-1",
      },
    },
    {
      enabled: true,
      excludeCustomerGroup: true,
      noAuthentication: true,
      staleTime: 15 * 60 * 1000,
    }
  );
}

export default {
  changePaymentMethod: {
    currentPaymentMethod:
      "Votre méthode de paiement actuelle est {{currentPaymentMethodName}}",
    enterCreditCard:
      "Saisissez ci-dessous une autre carte de crédit que vous souhaitez utiliser.",
    changePaymentMethod: "Changer la méthode de paiement",
  },
  renewProduct:
    "Il est possible de renouveler ce produit pour obtenir un meilleur prix.",
  renewOptions: "Possibilités de renouvellement",
} as const;

export default {
  form: {
    title: "Connection à Xplora",
    username: "Numéro de téléphone",
    password: "Veuillez saisir le code reçu par SMS",
    language: "Langue",
    submit: "Se connecter",
    continue: "Suivant",
    usernameValidationMessage: "Entrer le numéro de téléphone des parents.",
    otpHint: "",
  },
  logoutDialog: {
    title: "Utilisateur connecté ",
  },
  loginInfo: {
    title: "Avez-vous un abonnement Xplora?",
    content:
      "Veuillez noter que cela peut prendre jusqu'à 30 minutes avant que l'abonnement soit actif et que vous puissiez vous connecter à Mypage. Utilisez le numéro de téléphone du parent, et non celui de la smartwatch. Xplora Mypage est le site principal pour la gestion de votre abonnement. Vous y trouverez un aperçu des éléments suivants:\n • Informations sur la facture avec possibilité de la télécharger au format PDF.",
  },
} as const;

export default {
  changePaymentMethod: {
    currentPaymentMethod:
      "Deine aktuelle Zahlungsweise ist {{currentPaymentMethodName}}",
    enterCreditCard:
      "Gib unten eine andere Kreditkarte ein, die du verwenden möchtest.",
    changePaymentMethod: "Zahlungsweise ändern",
  },
  renewProduct:
    "Es ist möglich, dieses Produkt zu erneuern, um einen besseren Preis zu erhalten.",
  renewOptions: "Erneuerungsoptionen",
} as const;

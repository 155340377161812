import { ActionPosition } from "core/api";
import { ActionButton, useScreenContext } from "core/components";
import { omit } from "lodash";
import { useEffect, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

export interface OrderedActionButton extends ActionButton {
  sortOrder: number;
  // position of the action on the screen, view or widget
  position: ActionPosition;
}

interface ScreenActions {
  [actionId: string]: OrderedActionButton[];
}

interface ScreenActionsStore {
  actions: ScreenActions;
  register(key: string, actions: OrderedActionButton[]): void;
  unregister(key: string): void;
}

const useScreenActionsStore = create<ScreenActionsStore>()(
  devtools((set) => ({
    actions: {},
    register: (key, actions) =>
      set((state) => ({
        ...state,
        actions: { ...state.actions, [key]: actions },
      })),
    unregister: (key) =>
      set((state) => ({ ...state, actions: omit(state.actions, [key]) })),
  }))
);

/**
 * Subscribe actions to view context.
 *
 * NOTE: Actions should be memoized before usage of this hook
 * to prevent unnecessary rerenders.
 *
 * @param actions Ordered actions for topbar
 */
export function useRegisterActions(
  actions: OrderedActionButton[] | undefined
): void {
  const { register, unregister } = useScreenActionsStore();
  const uuid = useMemo(() => uuidv4(), []);

  useEffect(() => {
    if (actions && actions.length > 0) {
      register(uuid, actions);
      return () => unregister(uuid);
    }
  }, [actions, register, unregister, uuid]);
}

export function useScreenActions(): OrderedActionButton[] {
  const screenActionsMap = useScreenActionsStore((state) => state.actions);
  return useCombineOrderedActions(screenActionsMap);
}

/**
 * Combine actions to single list ordered by sort order.
 * The list will contain only actions that are positioned
 * in the screen top bar. Filters out disabled actions if hideDisabledActions is true.
 * @param actionsMap Action state mapped by unique key
 * @returns Combined actions
 */
function useCombineOrderedActions(
  actionsMap: ScreenActions
): OrderedActionButton[] {
  const { screenConfig } = useScreenContext();

  return sortOrderedActions(
    Object.values(actionsMap)
      .flatMap((actions) => actions)
      .filter(
        ({ position, disabled }) =>
          (position === ActionPosition.SCREEN_TOPBAR_ONLY ||
            position === ActionPosition.SCREEN_TOPBAR_AND_VIEW_BODY ||
            position === ActionPosition.SCREEN_TOPBAR_AND_WIDGET_BODY) &&
          !(disabled && screenConfig?.hideDisabledActions)
      )
  );
}

export function sortOrderedActions(
  actions: OrderedActionButton[]
): OrderedActionButton[] {
  return actions.sort(
    (a: OrderedActionButton, b: OrderedActionButton) =>
      a.sortOrder - b.sortOrder
  );
}

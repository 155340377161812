import dayjs from "dayjs";
import { getI18n } from "i18n";
import { IDBPCursorWithValueIteratorValue } from "idb";
import { Store } from "../store";
import { DB, HTTP_CACHE_KEY, HttpCacheDB, HttpCacheValue } from "./DBService";

type HttpCacheCursor = IDBPCursorWithValueIteratorValue<
  HttpCacheDB,
  ["HttpCache"],
  "HttpCache",
  unknown,
  "readwrite"
>;

/**
 * Manages Http Cache stored in IndexedDB
 */
class CacheService {
  /**
   * Checks the http cache if the request already is in cache and returns
   * it if it exists.
   * @param method Http method
   * @param url Request url
   * @returns A promise that resolves into cached response or null
   */
  async getResponseFromCache(
    method: string,
    url: string
  ): Promise<Response | null> {
    /**
     * If the request is for "data", then it will have been cached with the then-current appConfigId as part of the IDB key.
     * If the request is for "config", then this will have not been included. We must check for both possibilities.
     */
    const configRequestKey = this.createCacheKey(method, url, false);
    const dataRequestKey = this.createCacheKey(method, url, true);
    const configRequestResponse = await this.getFromDB(configRequestKey);
    const dataRequestResponse = await this.getFromDB(dataRequestKey);

    const response = configRequestResponse ?? dataRequestResponse;
    if (response) {
      if (dayjs(response.expires).isAfter(dayjs())) {
        const { headers, body } = response;
        const blob = new Blob([JSON.stringify(body, null, 2)], {
          type: "application/json",
        });
        return new Response(blob, {
          headers: new Headers(headers as HeadersInit),
          status: 200,
        });
      } else {
        this.removeFromDB(configRequestKey);
        this.removeFromDB(dataRequestKey);
      }
    }
    return null;
  }

  /**
   * Adds response to http cache
   *
   *
   * @param method Http method
   * @param response Response object
   * @param body Response body
   */
  async setResponseInCache(method: string, response: Response, body: any) {
    const expires = this.getTimeToLive(response.headers);
    if (expires) {
      const cacheStore = response.headers.get("bss-cache-store");
      /*
       *  The current app Config Id is included in the cache key for "data" requests
       *  due to these requests possibly containing data (such as URLs) that
       *  is only valid within one app configuration.
       *  App config id is not included in the api query key as we only know
       *  whether a request is for data or cache after getting a response from
       *  the server.
       */
      const request = this.createCacheKey(
        method,
        response.url,
        !!cacheStore && cacheStore === "data"
      );
      const headers = Array.from(response.headers.entries());
      this.setInDB({ request, headers, body, expires });
    }
  }

  /**
   * Clears all http cache
   */
  clearCache() {
    DB.deleteAll(HTTP_CACHE_KEY);
  }

  /**
   * Clear response in cache. Deletes all matching
   * queries from cache.
   *
   * @param method the method (currently ignored)
   * @param url The url
   */
  clearResponse(method: string, url: string) {
    this.deleteAllMatching(url);
  }

  private getFromDB(request: string): Promise<HttpCacheValue> | undefined {
    return DB.get(HTTP_CACHE_KEY, request);
  }

  private setInDB(cache: HttpCacheValue) {
    DB.put(HTTP_CACHE_KEY, cache);
  }

  private removeFromDB(request: string) {
    DB.delete(HTTP_CACHE_KEY, request);
  }

  private getTimeToLive(headers: Headers): number | null {
    if (headers.has("Cache-Control")) {
      const maxAge = this.getHeaderDirective(
        headers,
        "Cache-Control",
        "max-age"
      );
      if (maxAge) {
        return dayjs().add(parseInt(maxAge), "seconds").valueOf();
      }
    }

    return null;
  }

  private getHeaderDirective(
    headers: Headers,
    header: string,
    directive: string
  ): string {
    const headerValue = headers.get(header);
    if (headerValue) {
      const directives = headerValue.split(",");
      for (const currentDirective of directives) {
        const [name, value] = currentDirective.split("=");
        if (name === directive && value) {
          return value;
        }
      }
    }

    return "";
  }

  private createCacheKey(
    method: string,
    url: string,
    includeAppConfigId: boolean
  ) {
    // This is a workaround, we get a webpack loader error on undefined method if we call the utility method from here
    // const customerGroupId = getCurrentCustomerGroupId();
    // const appConfigId = getCurrentAppConfig();

    const appConfigId = includeAppConfigId
      ? (
          JSON.parse(
            sessionStorage.getItem("currentApplicationConfigId") || "{}"
          ) as Store<number>
        ).state
      : -1;

    const customerGroupId = sessionStorage.getItem("currentCustomerGroup");
    // End workaround.

    return `${method} ${url} lang:${
      getI18n().language
    } appConfigId:${appConfigId} customerGroupId:${customerGroupId}`;
  }

  /**
   * Deletes all http cache where the query matches a part
   * of the cache key
   * @param partialKey Partial of http cache key
   * @param query Comparison query. Defaults to 'include'
   * @returns
   */
  async deleteAllMatching(
    partialKey: string,
    query: "includes" | "startsWith" = "includes"
  ) {
    await this.deleteMatchingRows((cursor) => cursor.key?.[query](partialKey));
  }

  /**
   * Deletes all http cache matching target store
   * @param store Target cache store
   */
  async deleteCacheStore(store: "config" | "data") {
    await this.deleteMatchingRows(
      (cursor) =>
        !!new Headers(cursor.value.headers as HeadersInit)
          .get("bss-cache-store")
          ?.includes(store)
    );
  }

  private async deleteMatchingRows(
    deleteIf: (cursor: HttpCacheCursor) => boolean
  ) {
    await DB.createTransaction("HttpCache")?.then(async (tx) => {
      if (!tx) {
        return;
      }
      for await (const cursor of tx.store) {
        if (deleteIf(cursor)) {
          cursor.delete();
        }
      }
    });
  }
}

export const Cache = new CacheService();

import action from "i18n/lang/de/action";
import common from "i18n/lang/de/common";
import error from "i18n/lang/de/error";
import invoice from "i18n/lang/de/invoice";
import languages from "i18n/lang/de/languages";
import login from "./login";
import selfcare from "./selfcare";

export const de = {
  action,
  common,
  invoice,
  languages,
  error,
  login,
  selfcare,
} as const;

import { EditInventoryFlowData, EditInventoryFlowStep } from "core/api";
import { StepDataResolver } from "../models";

/**
 * Edit Inventory data resolver
 *
 * Formats Edit Inventory based on form field values
 */
export const editInventoryDataResolver: StepDataResolver<
  EditInventoryFlowStep,
  EditInventoryFlowData
> = ({ data, fieldValues }) => {
  return {
    statusId: fieldValues.statusId ?? data.statusId,
    locationId: fieldValues.locationId ?? data.locationId,
  };
};

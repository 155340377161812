import { ListItem, ListItemText } from "common/components";
import { FlowData, FlowStep } from "core/api";
import { useTranslation } from "i18n";
import { Fragment } from "react";

export interface EditInventoryConfirmationProps {
  step: FlowStep;
  flowData: FlowData;
}

/**
 * Sub component to confirmation. The purpose of this component is
 * to make the main Confirmation component more readable,
 * abstracting some of the logic into smaller sub components.
 *
 * EditInventoryConfirmation is specific to confirmation steps where
 * the flow contains a step with the uiComponent: "EditInventory".
 *
 * @param flowData data property from the flow object, this is fetched through
 * const flowData = flow.data[step.id]; in the parent component.
 *
 * @returns A JSX element with inventory details from the previous "EditInventory" step in the flow.
 */
export function EditInventoryConfirmation({
  step,
  flowData,
}: EditInventoryConfirmationProps): JSX.Element {
  const { t } = useTranslation(["inventory"]);
  const fieldFlowData =
    flowData.uiComponent === "EditInventory" ? flowData : undefined;

  const status = fieldFlowData?.statusList.find(
    (item) => item.id === fieldFlowData?.statusId.toString()
  );
  const location = fieldFlowData?.locationList.find(
    (item) => item.id === fieldFlowData?.locationId.toString()
  );

  return (
    <Fragment key={step.id}>
      <ListItem>
        <ListItemText
          primary={t("inventory:statusName")}
          secondary={status?.name}
        />
      </ListItem>

      <ListItem>
        <ListItemText
          primary={t("inventory:locationName")}
          secondary={location?.name}
        />
      </ListItem>
    </Fragment>
  );
}
